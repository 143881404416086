import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { getDetails } from '../../selectors';
import { classes } from './SubscriptionsListItemBass.st.css';
import { PAID_PLANS_APP_DEF_ID, STORES_APP_DEF_ID } from '../../constants';
import { RootState } from '../../state';
import { ActionType, Subscription, SubscriptionStatus } from '@wix/ambassador-billing-v1-subscription/types';
import { isInGracePeriod, isPendingCancellationSubscription, isRecurringSubscription } from '../../domainUtils';
import { Experiments } from '../../../../Experiments';
import { IActionCreators } from '../../../../types/internal-types';
import UpmCta from './UpmCta';
import CancelSubscriptionCta from './CancelSubscriptionCta';
import NavigateToVerticalCta from './NavigateToVerticalCta';
import PayNowCta from './PayNowCta';
import { useExperiments } from '@wix/yoshi-flow-editor';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

interface Props {
  subscription: Subscription;
}

const CallToActions = ({ subscription, details }: Props & RuntimeProps) => {
  const isPPSubscription = subscription.origin?.appId === PAID_PLANS_APP_DEF_ID;
  const isStoresSubscription = subscription.origin?.appId === STORES_APP_DEF_ID;
  const { experiments } = useExperiments();

  const hasBuyNewPlanStatus =
    (subscription.status &&
      [SubscriptionStatus.ENDED, SubscriptionStatus.CANCELED].indexOf(subscription.status) > -1) ||
    isPendingCancellationSubscription(subscription);
  const isRecurring = isRecurringSubscription(subscription);
  const isCancelActionAllowed = details?.allowedActions?.filter(
    (action) => action.actionType === (isRecurring ? ActionType.CYCLE_AUTO_RENEW_OFF : ActionType.CANCEL),
  ).length;
  const shouldShowPayNowCta = isInGracePeriod(subscription);
  const shouldShowUpmCta =
    experiments.enabled(Experiments.ENABLE_UPDATE_PAYMENT_METHOD) &&
    !!details?.allowedActions?.find((action) => action.actionType === ActionType.UPM);
  const shouldShowNavigateToVerticalCta = !!((isPPSubscription || isStoresSubscription) && hasBuyNewPlanStatus);
  const shouldShowCancelSubscriptionCta = !!(isCancelActionAllowed && !hasBuyNewPlanStatus);

  return (
    <div className={classes.ctaContainer}>
      {shouldShowPayNowCta && <PayNowCta subscription={subscription} />}
      {shouldShowUpmCta && <UpmCta subscription={subscription} />}
      {shouldShowNavigateToVerticalCta && <NavigateToVerticalCta subscription={subscription} />}
      {shouldShowCancelSubscriptionCta && <CancelSubscriptionCta subscription={subscription} />}
    </div>
  );
};

const mapRuntimeToProps = (state: RootState, { subscription }: Props, actionCreators: IActionCreators) => {
  const subscriptionId: string = subscription.id!;
  return {
    details: getDetails(state, subscriptionId),
    actionCreators,
  };
};

export default connect(mapRuntimeToProps)(CallToActions);
